var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.historicalDamages && _vm.historicalDamages.length
        ? [
            _c(
              "div",
              { staticClass: "text-xs-right handlepanel" },
              [
                _vm.hasPanelsOpen
                  ? _c("v-flex", [
                      _c("div", { staticClass: "panel" }, [
                        _c(
                          "span",
                          { on: { click: _vm.closeAllPanels } },
                          [
                            _vm._v(" sluit alle "),
                            _c("v-icon", [_vm._v("keyboard_arrow_up")])
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e(),
                !_vm.hasPanelsOpen
                  ? _c("v-flex", [
                      _c("div", { staticClass: "panel" }, [
                        _c(
                          "span",
                          { on: { click: _vm.openAllPanels } },
                          [
                            _vm._v(" open alle "),
                            _c("v-icon", [_vm._v("keyboard_arrow_down")])
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._l(_vm.historicalDamages, function(historicalDamage, index) {
              return _c(
                "v-expansion-panel",
                {
                  key: index,
                  staticClass: "damagePanel",
                  attrs: { expand: "" },
                  model: {
                    value: _vm.panels[index],
                    callback: function($$v) {
                      _vm.$set(_vm.panels, index, $$v)
                    },
                    expression: "panels[index]"
                  }
                },
                [
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _vm._v(
                          _vm._s(index + 1) +
                            ". " +
                            _vm._s(historicalDamage.name)
                        )
                      ]),
                      _c(
                        "v-card",
                        {
                          staticStyle: {
                            "border-top": "1px solid #ddd",
                            "border-bottom": "1px solid #ddd"
                          }
                        },
                        [
                          _c(
                            "v-card-text",
                            [
                              historicalDamage.answers
                                ? _c(
                                    "v-layout",
                                    {
                                      staticClass:
                                        "item__list item__list--reports",
                                      attrs: { wrap: "" }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "label",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs10: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v("Algemeen")
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v("Status:")
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        historicalDamage.answers
                                                          .historicalReport_Status
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v("Zelfde eigenaar:")
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        historicalDamage.answers
                                                          .historicalReport_ZelfdeEigenaar
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v("Opnamedatum:")
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "dateFormatTime"
                                                        )(
                                                          historicalDamage
                                                            .answers
                                                            .historicalReport_Opnamedatum
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v("Verslagdatum:")
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "dateFormatTime"
                                                        )(
                                                          historicalDamage
                                                            .answers
                                                            .historicalReport_Verslagdatum
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v("Naam expert:")
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        historicalDamage.answers
                                                          .historicalReport_Naamexpert
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v("Calculatie expert:")
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          historicalDamage
                                                            .answers
                                                            .historicalReport_Calculatieexpert
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v("Datum akkoord:")
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "dateFormatTime"
                                                        )(
                                                          historicalDamage
                                                            .answers
                                                            .historicalReport_DatumAkkoord
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              historicalDamage.answers
                                ? _c(
                                    "v-layout",
                                    {
                                      staticClass:
                                        "item__list item__list--reports",
                                      attrs: { wrap: "" }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "label",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs10: "" } },
                                                [_c("span", [_vm._v("Schade")])]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [_c("span", [_vm._v("ABC:")])]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        historicalDamage.answers
                                                          .historicalReport_ABCSchadesAantallen
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Keuze KUS / KEA / KH:"
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        historicalDamage.answers
                                                          .historicalReport_KeuzeKUSKEAKHC
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v("Finale kwijting:")
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        historicalDamage.answers
                                                          .historicalReport_FinaleKwijting
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v("Omschrijving:")
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        historicalDamage.answers
                                                          .historicalReport_OmschrijvingSchadelocatie
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Bedrag uitbetaling:"
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          historicalDamage
                                                            .answers
                                                            .historicalReport_BedragUitbetaling
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v("Nulmeting vereist:")
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        historicalDamage.answers
                                                          .historicalReport_NulMetingLight
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v("Bijzonderheden:")
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        historicalDamage.answers
                                                          .historicalReport_BIJZONDERHEDEN
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-layout",
                                {
                                  staticClass: "item__list item__list--reports",
                                  attrs: { wrap: "" }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "label",
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs10: "" } },
                                            [_c("span", [_vm._v("Bijlage")])]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  historicalDamage.media &&
                                  historicalDamage.media.length
                                    ? _vm._l(historicalDamage.media, function(
                                        media,
                                        i
                                      ) {
                                        return _c(
                                          "v-flex",
                                          {
                                            key: i,
                                            staticClass: "list__item",
                                            attrs: { xs12: "" }
                                          },
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                attrs: {
                                                  wrap: "",
                                                  "align-center": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "item__name item__link"
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href:
                                                                media.original,
                                                              target: "_blank"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  media.file_name
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      })
                                    : [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs12: "" } },
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                attrs: {
                                                  wrap: "",
                                                  "align-center": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " Er zijn geen bijlagen "
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            })
          ]
        : _c(
            "v-layout",
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("span", { staticClass: "fadedText" }, [
                  _vm._v("Er zijn geen historische schades")
                ])
              ])
            ],
            1
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }