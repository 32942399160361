import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { HistoricalReport } from '@/models/HistoricalReport';
import { formatDate, currency } from '@/support/String';

@Component<HistoricalDamagePanel>({
  filters: {
    dateFormatTime: (date: string) => {
      if (! date) { return ''; }
      return formatDate(date, 'dd-LL-yyyy HH:mm');
    },
    currency: (value: any) => currency(value, 'EUR'),
  },
})
export default class HistoricalDamagePanel extends Vue {
  @Prop()
  protected reportId!: string;

  protected historicalDamages: HistoricalReport[] = [];

  protected panels: {[key: number]: boolean}[] | null = [];

  protected hasPanelsOpen = false;

  protected mounted() {
    this.fetchHistoricalDamages();
  }

  protected fetchHistoricalDamages() {
    if (! this.reportId) {
      return;
    }

    new HistoricalReport()
      .include(['files'])
      .filter({ report: this.reportId })
      .all()
      .then((historicalDamages: HistoricalReport[]) => {
        this.historicalDamages = historicalDamages;
        this.openAllPanels();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected openAllPanels() {
    this.panels = [...Array(this.historicalDamages.keys())].map((_) => [true]);
    this.hasPanelsOpen = true;
  }

  protected closeAllPanels() {
    this.panels = [];
    this.hasPanelsOpen = false;
  }

  @Watch('panels')
  protected panelsChanged() {
    if (! this.panels) {
      return;
    }

    if (this.historicalDamages.length === this.panels.length) {
      const panelDamages = this.panels.filter((item: any) => item[0] === true);

      if (panelDamages.length === this.historicalDamages.length) {
        this.hasPanelsOpen = true;
      } else {
        this.hasPanelsOpen = false;
      }
    }
  }
}
